.menu {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 0;
    animation: ani 2.5s forwards;
    margin: 40px 0;
}

@keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
}


.menu__links {
    display: flex;
    align-items: flex-end;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu__point {
    margin: 0 10px;

}

.menu__list-item {
    display: flex;
    place-content: center;
    place-items: center;
    text-transform: uppercase;
    font-size: 15px;
    text-decoration: none;
    color: black;
}

.menu__page-link:hover {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
}

.menu__page-link_active {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
}

@media screen and (max-width: 799px) {
    .menu__links {
        flex-wrap: wrap;
        justify-content: center;
    }

    .menu__list-item {
        margin: 5px;
    }

    .menu__point {
        margin: 8px -5px;
    }
}

@media screen and (max-width: 399px) {
    .menu__profile-link {
        padding: 5px 12px;
        font-size: 10px;
    }
}