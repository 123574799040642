.about {
    display: flex;
    justify-content: center;
    animation: ani 2.5s forwards;
    padding-bottom: 40px;
}

.about-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 25px;
    max-width: 700px;
    width: 100%;
    margin: 0 auto 30px;
}

.about-block-image{
    width: 100%;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.about-block {
    display: flex;
    padding: 0 10px;
}
.about-image {
    height: 100%;
    object-fit: cover;
}

.about-text {
    width: 100%;
    margin: 0;
}

@keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
}