.services {
    display: flex;
    justify-content: center;
    animation: ani 2.5s forwards;
}

.service-header {
    font-size: 14px;
    align-self: center;
    text-transform: uppercase;
}

.services-content {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: 0 auto 30px;
}

.services-row {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.services-row-header {
    margin: 20px 0;
}

.services-title-header {
    width: 200px;
    margin: 0;
    font-weight: 500;
}

.services-subtitle-header {
    width: 200px;
    margin: 0;
    font-weight: 700;
    font-size: 12px;
}

.services-text-header {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.services-subtext-header {
    margin: 0;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
}

.services-text {
    margin: 0;
}

.services-title {
    width: 200px;
    margin: 0;
    font-size: 13px;
    font-weight: 400;
}

.services-plus {
    margin: 0;
    font-size: 22px;
    font-weight: 400;
}

.services-coin {
    width: 120px;
    text-align: center;
}

.services-footer {
    margin: 20px 0;
    font-size: 13px;
    font-weight: 400;
}

.services-pdf {
    width: 25px;
}

@keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@media screen and (max-width: 750px) {
    .services-content {
        margin: 0 10px 30px;
    }

    .services-title-header, .services-subtitle-header, .services-title {
        width: 100px;
        font-size: 11px;
    }

    .services-coin {
        width: 60px;
    }
}