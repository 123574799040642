.popup {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    visibility: visible;
    opacity: 1;
    transition: visibility 300ms linear, opacity 300ms linear;
}

.popup__title {
    margin: 34px 0 42px 36px;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
}

.popup__input {
    width: 358px;
    height: 27px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 30px;
}

.popup__form {
    align-self: center;
    text-align: center;
}

.popup__container {
    width: 430px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding-bottom: 37px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.15);
}

.button {
    border: 0;
    margin: 0;
    padding: 0;
    outline-style: none;
    cursor: pointer;
    color: #ffffff;
    background-color: rgba(0, 125, 215, 0.0);
}

.button_type_close {
    background-image: url(../../images/CloseIcon.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    position: relative;
    top: -8px;
    right: -240px;
}

.button_type_close:hover {
    opacity: 0.6;
}

.button_type_close-photo {
    position: relative;
    align-self: flex-end;
    right: -40px;
}

@media screen and (max-width: 767px) {
    .button_type_close {
        top: -17px;
        right: -131px;
        width: 20px;
        height: 20px;
    }

    .button_type_close-photo {
        top: -10px;
        right: -25px;
    }
}

.button_type_save {
    background: rgba(0, 0, 0);
    border-radius: 5px;
    width: 358px;
    height: 50px;
    margin-top: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

.button_type_save:hover {
    background: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 767px) {
    .button_type_save {
        width: 238px;
        height: 46px;
        font-size: 14px;
        line-height: 17px;
        margin-top: 22px;
    }
}

@media screen and (max-width: 767px) {
    .popup__container {
        margin: 0 19px;
        width: 282px;
        padding-bottom: 25px;
    }
}

@media screen and (max-width: 767px) {
    .popup__input {
        width: 238px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 767px) {
    .popup__title {
        font-size: 18px;
        line-height: 22px;
        margin: 25px 0 70px 22px;
    }
}
