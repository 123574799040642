.call-button-container {
    position: relative;
}

.call-button {
    position: fixed;
    bottom: 90px;
    right: 70px;
    background: linear-gradient(to right, #000000 0%, #504e4e 20%, #6b5f5f 40%, #000000 100%);
    background-size: 200% 100%;
    animation: shimmer 4.5s linear infinite;
    color: #ffffff;
    padding: 15px 25px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    z-index: 9999;
    font-size: 14px;
}

.popup-buttons {
    display: none;
    transition: all 0.3s ease;
    position: fixed;
    bottom: 150px;
    right: 80px;
    flex-direction: column;
    z-index: 9998;
}

.popup-buttons.show {
    display: block; /* Показать элемент */
}

.popup-button {
    border: none;
    cursor: pointer;
    border-radius: 10%; /* Делаем кнопку круглой */
    animation: fadeIn 0.5s ease-in-out; /* Анимация появления */
    background: rgba(255, 255, 255, 0.1);
    margin: 2px;
}

.popup-icon {
    width: 30px;
    padding: 2px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

@media screen and (max-width: 767px) {
    .call-button {
        bottom: 40px;
        right: 30px;
        padding: 10px 20px;
    }

    .popup-buttons {
        bottom: 85px;
        right: 35px;
    }
}