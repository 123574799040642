.contact {
    display: flex;
    justify-content: center;
    animation: ani 2.5s forwards;
}

.contact-content {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: 0 10px 30px;
}

.contact-header {
    display: flex;
    align-items: center;
}

.contact-title{
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    align-self: center;
    margin: 30px 10px;
}

.contact-text {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    margin-top: 15px;
}

.contact-text-phone {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    margin: 0;
}
.contact-line {
    flex: 1;
    height: 1px;
}

@keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
}