.main-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
}

.main-info_image{
    margin-left: 5px;
    width: 160px;
}

.main-info_imagepkus{
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
    margin-top: 10px;
}
.main-info_title {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 18px;
}

.main-info_subtitle {
    font-weight: normal;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
}

.main-info_text {
    font-weight: normal;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 20px;
}

.main-info_email {
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    margin-top: 15px;
}