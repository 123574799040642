.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 30px;
}

.loader__circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
